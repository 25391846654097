<template>
  <section v-if="history.length">
    <div class="d-flex mb-4 mt-4">
      <h4 class="bold text-muted text-dark mb-0">
        {{ $t("round_to_top_history") }}
      </h4>
    </div>

    <mega-card class="mt-4">
      <div class="card-body">
        <div
          class="w-100 navbar-label align-items-center text-dark"
          :class="{ 'mt-3': i > 0 }"
          v-for="(item, i) in history"
          :key="i"
        >
          <div class="content">
            <small>
              <span>{{ $t("round_to_top_date") }}:</span>
              <span class="strong">{{ item.a_time }}</span>
            </small>
            <small class="text-muted">User: {{ item.admin.email }}</small>
            <!-- <mega-button
							class="btn-primary rounded-0 w-100"
							@click="open(item.idt_user)"
						>{{ 'User' }}</mega-button> -->
          </div>
        </div>
      </div>
    </mega-card>
    <!-- <mega-modal
			class="modal-sm"
			:active="modal"
			:center="true"
			@onClose="modal = false"
		>
			<small class="strong">
				<span>{{ 'huy' }}</span>
			</small>
    </mega-modal> -->
  </section>
</template>

<script>
import Moment from "moment";
export default {
  name: "History",
  params: {
    modif_id: {
      type: String
    }
  },
  data() {
    return {
      history: [],
      roundId: this.$route.params.roundId,
      modal: false,
      modal_user: {}
    };
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      this.$api.v2base
        .get("/round/explore/top/history", {
          params: {
            idt_round: this.roundId,
            sn_id: this.$store.getters.selectedSnId
          }
        })
        .then(({ data }) => {
          const finalData = data.data.map(i => {
            return {
              a_time: new Date(i.a_time * 1000).toLocaleString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
              }),
              admin: i.admin
            };
          });
          this.history = finalData;
          this.$emit(
            "onGetLastPush",
            new Date(this.history[0].a_time).getTime()
          );
        });
      // this.$api.v2base
      //   .get("/round/explore/on_top/history")
      //   .then(({ data }) => {
      //     this.history = data.history.filter(
      //       el => el.idt_round === Number(this.roundId)
      //     );
      //     if (this.history.length) {
      //       this.history = this.history
      //         .sort((a, b) => {
      //           const timestampA = new Date(a.a_time).getTime();
      //           const timestampB = new Date(b.a_time).getTime();
      //           if (timestampA > timestampB) {
      //             return -1;
      //           }
      //           if (timestampA < timestampB) {
      //             return 1;
      //           }
      //           return 0;
      //         })
      //         .slice(0, 5);
      //       this.$emit(
      //         "onGetLastPush",
      //         new Date(this.history[0].a_time).getTime()
      //       );
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err);
      //     this.$alert.danger(this.$t("err_server"));
      //   });
    },
    getUser(id) {
      return this.$api.v1
        .get("/user", { params: { id } })
        .then(({ data }) => {
          console.log(data);
          this.modal_user = data.profile;
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },
    getDate(date) {
      const timestamp = new Date(date).getTime();
      Moment.locale(localStorage.getItem("language") || "ru");
      return Moment(timestamp).format("DD MMMM YYYY hh:mm:ss");
    },
    open(id) {
      this.modal = true;
      this.getUser(id);
    },
    close() {
      this.modal = false;
    }
  }
};
</script>
